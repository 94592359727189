@import '../common/_variables.scss';

// TODO move styles into paging-dots component
.slider-control-centercenter,
.slider-control-bottomcenter {
  ul {
    position: relative;
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    display: inline-block;
    margin: 0 3px;
    &.active button {
      background: $black;
    }
  }
  button {
    display: block;
    @include hide-text;
    width: 7px;
    height: 7px;
    position: relative;
    background-color: $black40;
    border-radius: 50%;
    cursor: pointer;
  }
}

.slider-control-bottomcenter {
  bottom: -20px !important;
  @include breakpoint(medium) {
    bottom: -30px !important;
  }
  @include breakpoint(small) {
    bottom: -25px !important;
  }
}

.slider-control-centerleft,
.slider-control-centerright {
  display: none;
}
