.drop-zone {
  .file-select {
    padding: 0;
    input[type='file'] {
      display: none;
    }
  }
  .drop-area {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
    min-height: 100px;
    border: 1px dashed #888;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preview.image {
    max-width: 200px;
    height: auto;
  }
}
