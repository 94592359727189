@import "swiper/css";
@import "swiper/css/scrollbar";
@import "swiper/css/pagination";
@import "swiper/css/effect-fade";

.swiper-container {
  overflow: hidden;
}
.swiper-scrollbar,
.swiper-scrollbar-drag {
  border-radius: 10px !important;
}

.swiper-scrollbar {
  width: 100% !important;
  height: 2px !important;
  left: 0 !important;
  bottom: 0 !important;
  background: #b9c0bc !important;
  position: relative !important;
}

.swiper-scrollbar-drag {
  background: #1a1a1a;
}
