// The main container element
.Collapsible {
  border-bottom: 1px solid $gray;
}

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 0 0 25px;
  border-top: 0;
  font-size: 14px;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 20px 0;

  &:after {
    content: "";
    background: url("/images/arrow_down_black.svg") center center no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 20px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: $gray;
  }
}
