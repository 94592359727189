.loading {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease;
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  &.inline {
    position: absolute;
    height: 100%;
  }
}

.spinner {
  width: 80px;
  height: 80px;
  margin: 100px auto;
  background-color: #111;
  border-radius: 100%;
  animation: sk-scaleout 1s infinite ease-in-out;
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
