.verticalTable {
  table {
    margin-bottom: 100px;
  }

  tbody {
    border: 0;
    background-color: transparent;
  }

  tr {
    border-bottom: 1px solid #7e7e7e;
    height: 90px;
    text-align: left;

    &:nth-child(even) {
      border-bottom: 1px solid #7e7e7e;
      background-color: transparent;
    }
  }

  td {
    color: #7e7e7e;
    padding: 0;

    &:first-of-type {
      font-size: 24px;
      line-height: 0.974;
      letter-spacing: -0.02em;
      font-family: $font-sans-serif-semibold;
      color: #000;
    }
  }
}
