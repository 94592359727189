$black: #141414;
$black20: #f1f1f1;
$black40: #d1d1d1;
$black60: #7e7e7e;
$black-full: #000000;
$charcoal: #303030;
$white: #fff;
$almost-white: #f0f0f0;
$gray: #8b8c8e;
$gray-2: #555555;
$gray-light: #f8f8f8;
$gray-light2: #ececec;
$gray-light3: #dfdfdf;
$gray-light4: #e5e5e5;
$gray-medium: #ebeaea;
$gray-medium2: #cccccc;
$gray-medium3: #969696;
$gray-dark: #3e3d3b;
$gray-dark2: #2b2b2b;
$gray-dark3: #666666;
$gray-affirm: #3e3e3e;
$gray-modals: #898989;
$gray-includes: #d8d8d8;
$red: #ff3347;
$red10: #c22e3a;
$red-dark: #d31627;
$blue-light: #f5f7f9;
$teal: #a3ded8;
$teal-dark: #93cec8;
$input-border: #2b2b2b;
$error-fg: #d71124;
$error-bg: #fbe6e8;

$headline: #3e3d3b;
$body-copy: #6c6f78;

$font-sans-serif: "Calibre-Regular", sans-serif;
$font-sans-serif-semibold: "Calibre-Semibold", sans-serif;

$content-page-padding: 105px;
$content-page-padding-medium: 65px;
$content-page-padding-small: 45px;

$header-z-index: 4;

@mixin hide-text() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin flexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@mixin breakpoint($size) {
  @if $size == xsmall-landscape {
    @media screen and (max-width: 37.5em) and (orientation: landscape),
      screen and (max-height: 20em) and (orientation: landscape) {
      @content;
    }
  } @else if $size == xsmall {
    @media screen and (max-height: 20em) and (orientation: portrait),
      screen and (max-height: 40em) and (orientation: portrait) {
      @content;
    }
  } @else if $size == small {
    @media screen and (max-width: 39.938em) {
      @content;
    }
  } @else if $size == small-landscape {
    @media screen and (max-width: 46em) and (max-height: 25.875em) and (orientation: landscape) {
      @content;
    }
  } @else if $size == medium {
    @media screen and (max-width: 63.9375em) {
      @content;
    }
  } @else if $size == medium-only {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      @content;
    }
  } @else if $size == large {
    @media screen and (max-width: 90em) {
      @content;
    }
  } @else if $size == xlarge {
    @media screen and (min-width: 90em) {
      @content;
    }
  } @else if $size == xxlarge {
    @media screen and (min-width: 100.063em) {
      @content;
    }
  } @else {
    @warn 'Breakpoint not supported';
  }
}
