.ReactModalPortal > div {
  z-index: 2;
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba($white, 0.6);

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}
