// Forms
select,
textarea,
input {
  box-shadow: none;
  border-color: $input-border;
  margin-bottom: 0;
  @include placeholder {
    color: $gray-dark3;
  }
}

textarea {
  min-height: 200px;
}

.field {
  margin: 0 0 0.75rem;

  .hint {
    clear: both;
    color: $black;
    font-size: 12px;
    line-height: 24px;
  }

  &.error {
    > label {
      color: $red-dark !important;

      .error-msg {
        display: inline-block;
        margin-top: 0.25rem;
      }
    }

    input {
      border-color: $red-dark !important;
    }
  }
}

.button {
  z-index: 2;
  background: none;
  border-width: 2px;
  border-radius: 2px;
  border-style: solid;
  border-color: $gray-dark2;
  color: $gray-dark2;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: all 200ms ease;
  text-decoration: none;
  letter-spacing: 3px;
  font-family: $font-sans-serif-semibold;
  font-size: 1rem;

  &:hover {
    background: $black-full;
    border-color: $black-full;
    color: $white;
  }

  &:focus {
    background: none;
    color: $black-full;
  }

  &.solid {
    background: $gray-dark2;
    color: $white;

    &:hover {
      background: $black-full;
      color: $white;
    }
  }

  &.inverted {
    color: $white;
    background: $gray-dark2;

    &:hover {
      color: $white;
      background: $black-full;
    }
  }

  &.solid-white {
    color: $black;
    border-color: $white;
    background: $white;

    &:hover {
      color: $white;
      border-color: $black;
      background: $gray-dark2;
    }
  }

  &.transparent-white {
    color: $almost-white;
    border-color: $almost-white;
    background: tranparent;

    &:hover {
      color: $white;
      border-color: $white;
      background: rgba(0, 0, 0, 0.2);
    }
  }

  @include breakpoint(small) {
    width: 90%;
  }
}

.alert {
  background: $black;
  color: $white;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
