.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  .llls__modal__overlay {
    position: fixed;
    inset: 0;
    z-index: 1050;
    background-color: rgba($gray-dark2, 0.5) !important;
    backdrop-filter: blur(10px);
  }

  .llls__modal__content {
    max-width: 100%;
    height: 100%;
    outline: none;
  }
}
