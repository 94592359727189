.status-message {
  background-color: $gray-light4;
  padding: 8px 0;
  @include breakpoint(small) {
    margin-top: 100px;
  }
}
#transfer-list {
  padding: 50px 50px;
  min-width: 500px;
  header {
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-medium;
    margin-bottom: 1rem;
    p {
      margin: 0 auto;
      max-width: 500px;
    }
  }
  dl {
    width: 80%;
    overflow: hidden;
    padding: 0;
    margin: 0 auto;
    text-align: left;
  }
  dt {
    float: left;
    width: 40%;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  }
  dd {
    float: left;
    width: 60%;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  }
  button {
    padding: 1rem;
    min-width: 14rem;
  }
  @include breakpoint(small) {
    min-width: 250px;
    dl {
      width: 100%;
      font-size: 14px;
      dt {
        width: 100%;
        text-align: left;
        margin-bottom: 8px;
      }
      dd {
        text-align: left;
        width: 100%;
      }
    }
    button {
      width: 60%;
    }
  }
  .transfer-item {
    border-bottom: 1px solid $gray-light4;
    padding-bottom: 16px;
    button {
      margin-top: 16px;
    }
  }
  .transfer-footer {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 1rem;
  }
}
