#reset-password {
  .grid-container {
    section {
      margin: 100px 0;
    }
  }
  .reset-pswd-form {
    margin: 0 auto;
    padding: 2rem;
    max-width: 500px;

    button {
      margin-top: 2rem;
      &:disabled {
        background: $black;
      }
    }

    .confirmation {
      margin-top: 0.5rem;
      background: #5cb849;
      color: $white;
      padding: 1rem;
    }

    .password {
      margin-top: 0.25rem;
    }
  }
}
