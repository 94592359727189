.modal-login-container {
  @include flexcenter;
  height: 100%;
}

.modal-login {
  background: $white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  max-width: 410px;
  width: 100%;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  outline: 0;
  position: relative;

  .sign-in,
  .sign-up {
    padding-top: 0.75rem;
  }

  .row {
    width: 100%;
  }

  header {
    text-align: right;
  }

  input[type="submit"],
  button {
    width: 100%;
    display: block;
    text-align: center;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 3px;
    text-align: center;
  }

  .close {
    width: 1.15rem;
    height: 1.15rem;
    display: inline-block;
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
  }

  .sign-in {
    .password-link-wrap {
      display: flex;
      justify-content: center;
    }

    .password-link {
      width: auto;
      margin-bottom: 1.5rem;
      text-decoration: underline;
      cursor: pointer;
      color: #000;
    }
  }

  .forgot-password.sent {
    @include flexcenter;
    flex-direction: column;

    p {
      padding: 4rem 0;
    }
  }

  .forgot-password {
    .prompt {
      font-family: $font-sans-serif-semibold;
      font-size: 1.25rem;
      margin-bottom: 1.25rem;
    }
  }

  .small-text {
    color: #7e7e7e;
    font-family: $font-sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    &.alert-text {
      color: $red;
    }
  }

  .alert {
    background: $red;
  }
}
