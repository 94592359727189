// reviews/Reviews.tsx
#product-reviews {
  margin-bottom: 4rem;
  cite {
    font-weight: bold;
    font-style: normal;
    color: $black;
    font-size: 18px;
    &:before {
      display: none;
    }
  }
  address,
  time,
  p {
    color: $body-copy;
    font-style: normal;
  }
  h3 {
    text-align: center;
    padding: 2rem 0;
  }
  h6 {
    text-align: left;
    margin-bottom: 0.25rem;
  }
  header {
    border-bottom: 3px solid $black;
    padding-bottom: 2rem;
    .review-summary {
      display: inline-block;
    }
    .grid-x {
      align-items: center;
      justify-content: center;
    }
    .widget-ratings {
      margin-left: 0;
    }
    .controls {
      text-align: right;
      select {
        margin-right: 1.5rem;
      }
    }
    .rating {
      span {
        width: 32px;
        height: 32px;
      }
    }
    @include breakpoint(medium) {
      border-bottom: 2px solid $black;
      padding-bottom: 2rem;
      .review-summary {
        width: 100%;
      }
      .avg {
        margin-bottom: 2rem;
      }
      .widget-ratings {
        width: 100%;
        display: block;
      }
      .controls {
        text-align: center;
        display: flex;
      }
      .sort-order {
        margin: 0;
      }
    }
  }
  .review-product-cta {
    h4 {
      text-align: left;
      font-size: 1.2rem;
    }
    .product-image {
      max-width: 100px;
      text-align: center;
    }
    .product-info {
      padding-left: 1rem;
    }
    .review-summary {
      display: block;
      margin-bottom: 1rem;
    }
    .actual {
      top: 0;
      font-weight: bold;
      font-size: 1rem;
      color: $black;
    }
    .avg > * {
      margin: 0;
    }
    .sep {
      margin: 0 0.25rem;
    }
    .widget-ratings {
      margin-right: 0.5rem;
    }
    .button {
      padding: 0.5rem 1rem;
      min-width: 10rem;
    }
  }

  .avg > * {
    margin: 0 0.2rem;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  select.sort-order {
    width: auto;
  }
  ul {
    list-style: none;
    margin: 0;
  }
  footer.more-reviews {
    text-align: center;
    padding-top: 2rem;
    margin: 2rem 0;
  }
  .review {
    border-bottom: 1px solid $gray-medium2;
    padding: 2rem 0;
    h6 {
      margin-bottom: 1rem;
    }
    &-buttons a {
      font-weight: bold;
    }
    &-helpful {
      margin-right: 1rem;
      color: $black;
    }
    &-feedback {
      font-size: 14px;
      margin-top: 1rem;
      color: $body-copy;
      font-style: normal;
    }
    .sep {
      margin: 0 0.5rem;
    }
  }
  @include breakpoint(medium) {
    .review {
      &-helpful {
        margin-right: 0;
        margin-top: 1rem;
        display: block;
      }
    }
    aside {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
    .widget-svg {
      width: 25px !important;
    }
    .actual {
      font-size: 1.5rem;
    }
    .sort-order {
      margin-bottom: 1rem;
    }
  }
  @include breakpoint(small) {
    .review-product-cta .product-info {
      margin-top: 1rem;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .product-image {
      margin: 0 auto;
    }
    .review-product-cta {
      h4 {
        text-align: center;
      }
    }
    .review-helpful {
      margin-top: 0;
      padding-top: 0;
    }
    .review-feedback {
      margin-top: 0;
    }
    .review-summary {
      text-align: center;
    }
    header .controls {
      display: block;
    }
  }
}

// reviews/review-form/Form.tsx
#product-review-form {
  z-index: 10;
  position: relative;
  background: #fff;
  padding: 0 4rem;

  header {
    text-align: left;
    border-bottom: none;
    padding: 2rem 0 1rem 0;
  }
  h6 {
    text-align: left;
  }
  hr {
    border-bottom-color: $black;
    margin: 2rem auto;
  }
  .error {
    font-size: 14px;
    color: #ff5f33;
  }
  .column {
    position: relative;
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include breakpoint(small) {
    padding: 0 0.5rem;
  }
}
