// Fix foundation
.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  float: left !important;
}

.content-page {
  padding: $content-page-padding 0;

  @include breakpoint(medium) {
    padding: $content-page-padding-medium 0;
  }
  @include breakpoint(small) {
    padding: $content-page-padding-small 0;
  }
}

.scene {
  position: relative;
  background: $white;
  width: 100%;
  left: 0;
}

.show-for-xlarge-only {
  display: none !important;
  @include breakpoint(xlarge) {
    display: block !important;
  }
}
.hide-for-xlarge {
  @include breakpoint(xlarge) {
    display: none !important;
  }
}

.red {
  color: $red10;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.strikeout {
  color: $gray-medium3;
  text-decoration: line-through;
  padding-right: 8px;
}
