@font-face {
  font-family: "Calibre-Bold";
  src: url("/fonts/Calibre-Bold.woff2") format("woff2"),
    url("/fonts/Calibre-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "CalibrePractice-LightStencil";
  src: url("/fonts/CalibrePractice-LightStencil.woff2") format("woff2"),
    url("/fonts/CalibrePractice-LightStencil.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Black";
  src: url("/fonts/Calibre-Black.woff2") format("woff2"),
    url("/fonts/Calibre-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Regular";
  src: url("/fonts/Calibre-Regular.woff2") format("woff2"),
    url("/fonts/Calibre-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Medium";
  src: url("/fonts/Calibre-Medium.woff2") format("woff2"),
    url("/fonts/Calibre-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Semibold";
  src: url("/fonts/Calibre-Semibold.woff2") format("woff2"),
    url("/fonts/Calibre-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Thin";
  src: url("/fonts/Calibre-Thin.woff2") format("woff2"),
    url("/fonts/Calibre-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Calibre-Light";
  src: url("/fonts/Calibre-Light.woff2") format("woff2"),
    url("/fonts/Calibre-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}
